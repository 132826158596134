import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.css";
//import ReactFullpage from '../../../components';
import ReactFullpage from "@fullpage/react-fullpage";
import { Item } from "@fullpage/react-fullpage";
import SectionsContent, {getSectionById} from "./SectionsContent";
import { useAppSelector, useAppDispatch } from "./app/hooks";
import {
  setSlideErrorOked,
  addSectionsViewable,
  addSlideOkToProceed
} from "./features/counter/counterSlice";
import {
  gotoNextSection,
  gotoPrevSection
} from "./navigation/navigationUtils";
import { store } from "./app/store";
import {createItem, postCompleted} from './data';
import {getUrlParams} from './utils';

const SEL = "custom-section";
const pluginWrapper = () => {
  /*
   * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
   */
};
const SECTION_SEL = `.${SEL}`;

function onLoad(dispatch: any) {
  createItem();
  dispatch(addSectionsViewable( 0 ));
  dispatch(addSlideOkToProceed( {sectionIndex: 0, slideId: 0} ) );
}

function App() {
  const [loaded, setIsLoaded] = useState(true);
  const dispatch = useAppDispatch();

  /* run on load */
  React.useEffect(() => onLoad(dispatch), []);
  
  

  const triggerScroll = (goingUp: boolean) => {
    if (goingUp) {
      (window as any).fullpage_api.moveSectionUp();
    } else {
      (window as any).fullpage_api.moveSectionDown();
    }
  };
  const SEL = "custom-section";
  const SECTION_SEL = `.${SEL}`;
  const [sections, setSections] = useState([
    {
      text: "Section 1",
    },
  ]);

  const onSectionLeave = (origin: Item, destination: Item, direction: string) => {
    const sectionsViewable = store.getState()?.counter.sectionsViewable;
    const sectionContent = getSectionById(origin.index);
    console.log("sectionsViewable");
    console.log(sectionsViewable);
    //if (!sectionsViewable.includes(destination.index)) {
    const urlParams = new URLSearchParams(window.location.search);

    if (
      window.location.search.indexOf("admin") == -1 &&
      sectionsViewable.length < destination.index + 1
    ) {
      if (sectionContent.warningMessage) {
        if (sectionContent.validationWarnOnly) {
          dispatch(addSectionsViewable( destination.index ));
        }
        alert(sectionContent.warningMessage);
      }
      return false;
    }
    
    if (destination.isLast) {
      postCompleted()
    }
  };

  const onSlideLeave = (section: Item, origin: Item, destination: Item, direction: string) => {
    const slidesOkToProceed = store.getState()?.counter.slidesOkToProceed;
    const slidesOked =  store.getState()?.counter.slidesOked;
  
    const sectionContent = getSectionById(section.index);
    //slidesConfig may be set individually for each slide, or for all slides in the section
    const slidesConfig = sectionContent.slidesConfig && sectionContent.slidesConfig[origin.index] ? sectionContent.slidesConfig[origin.index] : (sectionContent.slidesConfig || {});
    const warningMessage = slidesConfig.warningMessage;
    const validationWarnOnly = slidesConfig.validationWarnOnly;
    const isSlideOkToProceed = (slidesOkToProceed[section.index] || []).filter((o:any) => o == origin.index).length ||
      slidesConfig.okToProceed ||
      ((slidesOked[section.index] || []).filter((o:any) => o == origin.index).length && validationWarnOnly);

    //Deny proceeding, if applicable
    if (
      window.location.search.indexOf("admin") == -1 &&
      direction == 'right' &&
      !slidesConfig.okToProceed &&
      (origin.index < destination.index || destination.index == 0) &&
      !( isSlideOkToProceed )
    ) {
      if (warningMessage != undefined) {
        alert(warningMessage);
        dispatch(setSlideErrorOked({sectionIndex: section.index, slideId: origin.index}));
      }
      try{
        return false;
      } finally {
        (window as any).fullpage_api.reBuild();
      }
    //Go to next section if applicable
    } else if ( direction == 'right' && destination.index == 0 && (isSlideOkToProceed) ) {
      dispatch(addSectionsViewable( section.index + 1 ));
      gotoNextSection();
      return false;
    //Go to previous section if applicable
    } else if ( direction == 'left' && origin.index == 0 ) {
      gotoPrevSection();
      return false;
    }
  };

  return (
    <div className="App">
      <ReactFullpage
        debug
        controlArrows={false}
        navigation={false}
        responsiveSlides={true}
        pluginWrapper={pluginWrapper}
        licenseKey={"OPEN-SOURCE-GPLV3-LICENSE"} // Get one from https://alvarotrigo.com/fullPage/pricing/
        animateAnchor={false}
        onLeave={onSectionLeave}
        onSlideLeave={onSlideLeave}
        responsiveWidth={350}
        render={({ state, fullpageApi }:any) => (
          <ReactFullpage.Wrapper>
            <SectionsContent />
          </ReactFullpage.Wrapper>
        )}
      />

    </div>
  );
}

export default App;
