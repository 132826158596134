import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";


export const StyledTextField0 = styled(TextField)(({ theme }) => ({
    maxWidth: "750px",
    paddingTop: "50px",
    fontSize: "15pt",
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    root: {
      "& label.Mui-focused": {
        color: "white",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "yellow",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "yellow",
        }
      },
    },
    color: "#FFF",
    "& .MuiOutlinedInput-root": {
      "&:hover, &.Mui-focusVisible": {
        boxShadow: `0px 0px 0px 8px ${alpha("#FFF", 0.16)}`,
        borderColor: "white",
        color: "white",
      },
      "&:hover, &.MuiOutlinedInput-notchedOutline": {
        boxShadow: `0px 0px 0px 8px ${alpha("#FFF", 0.16)}`,
        borderColor: "white",
        color: "white",
      },
      "&.Mui-active": {
        boxShadow: `0px 0px 0px 14px ${alpha("#FFF", 0.16)}`,
        borderColor: "white",
        color: "white",
      },
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        color: "white",
      },
      "&.MuiOutlinedInput-root": {
        color: "white",
      },
    },
  }));


  export const StyledTextField = ({multiline,
    rows,
    className,
    onChange, onBlur, style}: {multiline?: any,
      rows?: any,
      className?: any,
      onChange?: any, onBlur?: any, style?: any}) => (
<textarea
  // multiline = {multiline}
  rows = {rows}
  className = {className + ' styled-text-field'}
  onChange = {onChange}
  onBlur = {onBlur}
  style = {style}
/>
  )