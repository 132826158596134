import React, { useState } from "react";
import ICard from './ICard';
//import "./card.scss";
import "./card-flip.scss";


function Card(props: ICard) {
  return (
    <div className="cards">
    <a className="card" href="#">
    <div className={"card-body" + (!props.isShowingCardFront ? " flipped" : "")}>

      <Front {...props} />
      <Back {...props} />

    </div>
  </a>
  </div>
  );
}

function Front(props: ICard) {
  return (
    <div className="card-front">
      {props.textFront && (<h1>{props.textFront}</h1>)}
      {props.contentsFront && (props.contentsFront)}
    </div>
  );
}

function Back(props: ICard) {
  return (
    <div className="card-back">
      {props.textFront && (<h1>{props.textFront}</h1>)}
      {props.textBack && (<p>{props.textBack}</p>)}
      {props.contentsBack && (props.contentsBack)}
    </div>
  );
}

export default Card;