import React, { useState, useEffect } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import { alpha, styled } from '@mui/material/styles';

  const PersonalValueContainer = styled('div')(({ theme }) => ({
    margin: '0px 15px 40px',
    [theme.breakpoints.down('lg')]: {
      margin: '0px 15px 30px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px 5px 15px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 3px 3px',
    }
  }));

  const PersonalValue = styled(ToggleButton)(({ theme }) => ({
    fontSize: '16pt',
    padding: '20px',
    margin: '5px 12px',
    backgroundColor: 'rgb(25 178 210 / 80%)',
    '&:hover': {
       background: "rgb(25 178 210 / 80%)",
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16pt',
      padding: '15px',
      margin: '5px 10px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14pt',
      padding: '10px',
      margin: '0px 7px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12pt',
      padding: '3px',
      margin: '0px 3px',
    }
  }));

  const PersonalValues = ({values}: {values: string[]}) => {
    return <PersonalValueContainer>
        {values.map(o => 
            (<PersonalValue value={o}>{o}</PersonalValue>)  
        )}
    </PersonalValueContainer>
  }
export default PersonalValues;