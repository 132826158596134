import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import ISlide from "../ISlide";
import Text from "../../components/Text";
import Grid from "@mui/material/Grid";
import {
  setPersonalValues,
  selectPersonalValues,
} from "../../features/counter/counterSlice";
import { postUpdate } from "../../data";
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const StyledButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: '7px',
  fontSize: '16pt',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
    fontSize: '16pt',
    margin: "5px"
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(.5),
    fontSize: '14pt',
    margin: '3px'
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.4),
    fontSize: '12pt',
    margin: '3px',
  }
}));


const addMaxThree = (newObj: any, arr: any[]) => {
  var newArr = [...arr, newObj];
  if (newArr.length > 3) {
    newArr.shift();
  }
  return newArr;
};

function AboutYou({ setSlideOkToProceed, setSlideNotOkToProceed }: ISlide) {

  const personalValues = useAppSelector(selectPersonalValues);
  const dispatch = useAppDispatch();

  const isSelected = (value: string) =>
    personalValues.filter((o) => o == value)!.length > 0;

  const toggleSelected = (event: any, selectedTitle: any) => {
    let newValues = [];
    if (isSelected(selectedTitle)) {
      newValues = personalValues.filter((o) => o != selectedTitle);
      dispatch(setPersonalValues(newValues));
    } else {
      newValues = addMaxThree(selectedTitle, personalValues);
      dispatch(setPersonalValues(newValues));
    }
    if (newValues.length > 1) {
      setSlideOkToProceed();
    } else {
      setSlideNotOkToProceed();
    }
    postUpdate("ValuesAffirmation_Values", newValues.join(", "));
  };

  const ValueButton = ({ title: title }: { title: string }) => {
    return (
        <StyledButton
          value={title}
          selected={isSelected(title)}
          onChange={toggleSelected}
        >
          {title}
        </StyledButton>
    );
  };

  return (
    <>
        <Text variant="h4">
          Take a moment to look at the values below. Then pick
          the 2 or 3 values that are{" "}
          <span style={{ textDecoration: "underline" }}>most</span> important to
          you.
        </Text>
        <div className="spacer-small"></div>
        <Grid item xs={12} sm={6} md={3} id="AboutYouButtons">
          <ValueButton title="Relationships with Family or Friends" />
          <ValueButton title="Spiritual or Religious Values" />
          <ValueButton title="Kindness and Respect for Others" />
          <ValueButton title="Sense of Humor" />
          <ValueButton title="Growth and Learning" />
          <ValueButton title="Belonging to a Social Group" />
          <ValueButton title="Creativity" />
          <ValueButton title="Independence" />
          <ValueButton title="Politics" />
          <ValueButton title="Sports Ability" />
        </Grid>
    </>
  );
}

export default AboutYou;
