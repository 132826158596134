import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Text from './Text';

export default function KnowledgeIsPowerCard ({title, content, backgroundColor}: {title:string|JSX.Element, content:any, backgroundColor: string}) {
  return (
    <Card sx={{ maxWidth: 345 }} style={{backgroundColor: backgroundColor}} className="static-card">
    <CardHeader
      
      title={typeof(title) === 'string' ? <Text variant="h3">{title}</Text> : title}
      className={''}
    />
    <CardContent style={{backgroundColor: 'rgba(255, 255, 255, .7)'}}>
      {/* <Typography variant="body2"> */}
        <Text>{content}</Text>
      {/* </Typography> */}
    </CardContent>
  </Card>
  )
}