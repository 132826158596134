// import "./styles.css";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

interface IDropdown {
    onChange: any;
    style?: any;
    value: any;
    children: any;
}

let theme = createTheme({
    // components: {
    //     MuiSelect: {
    //       defaultProps: {
    //             style: {
    //               boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    //         },
    //       },
    //       styleOverrides: {
    //         select: {
    //             color: "#fff",
    //             borderColor: "#fff"
    //       },
    //       },
    //     },
    //     MuiFormLabel: {
    //       styleOverrides: {
    //         root: {
    //             color: "#fff"
    //       },
    //     },
    //       defaultProps: {
    //         style: {
    //           color: "white",
    //         },
    //     },
    //   },
    //   MuiInputLabel: {
    //     styleOverrides: {
    //       root: {
    //         "&.MuiFormLabel-colorPrimary": {
    //           color: "red"
    //         },
    //           color: "#fff"
    //     },
    //   },
    //     defaultProps: {
    //       style: {
    //         color: "white",
    //       },
    //   },
    // },
    // }
}
);



theme = responsiveFontSizes(theme, {factor: 5});

// let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);


export default function Dropdown({value, style, onChange, children}: IDropdown) {
    
  return (
    <ThemeProvider theme={theme}>
        <Select
          value={value}
          sx={{
            select: {
              // '.MuiOutlinedInput-notchedOutline': {
              //   borderColor: '#FFF',
              // },
              // '&:hover .MuiOutlinedInput-notchedOutline': {
              //   borderColor: '#FFF',
              // },
              // '.MuiInputLabel-root': {
              //   color: '#FFF'
              // }
            }}}
          onChange={onChange}
          style={style}
          // className="whiteDropdown"
        >
          {children}
        </Select>
    </ThemeProvider>
  );
}
