import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import Text from '../../components/Text';
import ImgLayout from '../../components/ImgLayout';
import image6 from '../../images/img6.svg';
import {postUpdate} from '../../data';
import SlideNavigation from "../../navigation/SlideNavigation";
import ICardCollection from '../ICardCollection';

function Closing({isLastSlide}: {isLastSlide: boolean}) {
  return (
    <>
        <Text variant="pageTitle">
            Thank you for your time. It's an honor to be part of your pregnancy journey.
          </Text>

          <Text>
            Your partner in health, <br/>
            The Regional Perinatal Service Center
          </Text>
          <Text style={{display: isLastSlide ? 'none' : 'block'}}>
            Please click <b>Next</b> to continue...
          </Text>
          <Text>
            This is a general message, but one sent with your well-being in mind. If you have any questions, please contact your doctor.
          </Text>
    </>
      
  );
}

export default Closing;
