import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Navigation from '../../navigation/NavigationButtons';
import {gotoNextSection, gotoNextSlide, gotoPrevSection, gotoPrevSlide, getCurrentSlideIndex} from '../../navigation/navigationUtils';
import "../../App.css";
import Text from '../../components/Text';


const cards = [
  {
    title: "Why?",
    backgroundColor: "#93E1D8",
    content: (
      <ul>
        <li>Gestational diabetes increases the risk for type 2 diabetes after delivery.</li>
        <li>Testing <b>soon after delivery</b> lets you and your doctor determine the best next steps to stay healthy.</li>
        <li>If you detect type 2 diabetes, you can treat it right away and prevent complications.</li>
      </ul>
    )
  },
  {
    title: "What is this test?",
    //backgroundColor: "rgb(255 114 79 / 80%)",
    backgroundColor: "#93E1D8",
    content: (
      <ul>
        <li>The test is called a 75-gram <b>oral glucose tolerance test (OGTT)</b>.</li>
        <li>This is the most accurate type of test for diabetes after delivery.</li>
        <li>Other types of tests may be easier, but they are not as accurate because of the special physical changes that happen around delivery.</li>
      </ul>
    )
  },
  {
    title: "How much?",
    //backgroundColor: "#FFA69E",
    backgroundColor: "#93E1D8",
    content: (
      <ul>
        <li>For most patients, this test is <b>free</b>.</li>
        {/* <li>If you have Medi-Cal: Do this test within 60 days (8½ weeks) after delivery - before insurance coverage ends.</li> */}
      </ul>
    )
  },
  {
    title: "When?",
    //backgroundColor: "rgb(79 255 145 / 80%)",
    backgroundColor: "#93E1D8",
    content: (
      <ul>
        <li>Do the test around <b>6 weeks after delivery</b>.</li>
        <li>Do the test <b>early in the morning</b>. Don't eat or drink for 10 hours before the test (but you can drink water).</li>
      </ul>
    )
  },
  {
    title: "Where?",
    //backgroundColor: "rgb(255 234 158)",
    backgroundColor: "#93E1D8",
    content: (
      <ul>
        <li>Do the test at your local KP laboratory.</li>
        <li>Your test has been ordered. You won't need any paperwork.</li>
      </ul>
    )
  },
  {
    title: "How?",
    //backgroundColor: "rgb(79 195 255 / 80%)",
    backgroundColor: "#93E1D8",
    content: (
      <div>
        <p>The test has 4 steps:</p>
      <ol>
        <li>Give a blood sample</li>
        <li>Drink a sugary liquid</li>
        <li>Wait 2 hours</li>
        <li>Give another blood sample</li>
      </ol>
      <p>Results will be sent to your kp.org account. Your provider will contact you if the results are abnormal.</p>
    </div>
    )
  },  
]

const EnhancedHealthCard = ({title, content, backgroundColor}: {title:string, content:any, backgroundColor: string}) => {
  return (
    <Card sx={{ maxWidth: 345 }} style={{backgroundColor: backgroundColor}} className="static-card">
    <CardHeader
      
      title={<Text variant="h2">{title}</Text>}
      className={''}
    />
    <CardContent style={{backgroundColor: 'rgba(255, 255, 255, .7)'}}>
        <Text>{content}</Text>
    </CardContent>
  </Card>
  )
}

function EnahncedHealthSlides({id}: {id: string}) {
  const clickNext = function() {
    gotoNextSlide();
  }

  const clickPrev = function() {
    gotoPrevSlide();
  }

  return (
  <div key={'asd2'} className={'section'} id={id}>
  {cards.map(({title, content, backgroundColor}, i, arr) => (
    <div className="slide" key={`EnhancedHealth${i}`}>
      <div className="slide-inner">
        <div>
          <EnhancedHealthCard 
            title={title}
            content={content}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
    </div>

  ) )}
  
    <Navigation
      goForward={clickNext}
      goBack={clickPrev}
      showHorizontalBack={getCurrentSlideIndex() != 0}
      showHorizontalForward={getCurrentSlideIndex() != cards.length - 1}
      absolutePosition={true}
    />
  
  </div>
  )
}

export default EnahncedHealthSlides;
