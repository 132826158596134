import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import Text from '../../components/Text';
import ImgLayout from '../../components/ImgLayout';
import image6 from '../../images/img6.svg';
import {postUpdate} from '../../data';
import SlideNavigation from "../../navigation/SlideNavigation";
import ICardCollection from '../ICardCollection';

function Bullet (props: any) {
  return (<p className='bullet'><Text>{props.children}</Text></p>);
}

function Closing() {

  return (
    <>
        <Text variant="pageTitle" style={{marginLeft: 'auto', marginRight: 'auto'}}>
            Be sure to consider <b>Wellness Coaching</b> after delivery, too.
          </Text>

          <Bullet><b>Kaiser Permanente Wellness Coaching</b> can help you sleep better, reduce stress, manage weight, eat well, and be active.</Bullet>
          <Bullet><b>It's free &amp; convenient.</b> Get personal coaching by phone at flexible times, even evenings and weekends - at no cost.</Bullet>
          <Bullet><b>It works!</b> Research shows that coaching helps patients reach a healthier weight.</Bullet>
    </>
      
  );
}

export default Closing;
