import React from 'react';
import Chip from '@mui/material/Chip';
import { alpha, styled } from "@mui/material/styles";
import Text from '../../components/Text';

const StyledChip = styled(Chip)(({ customColor }: {customColor:string}) => ({
    'alignSelf': 'center',
    'fontSize': '25pt',
    'padding': '30px',
    'backgroundColor': `${customColor} !important`,
  }));

  const ChipHeadline = ({text, color, isSmall}: {text: any, color: string, isSmall?: boolean}) => {
    return <StyledChip className={isSmall ? 'chip-small' : ''} label={<Text variant={isSmall ? "h5" : "h3"}>{text}</Text>} customColor={color} />;
  }
  
export default ChipHeadline;