import React, { useState, useEffect } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import ISlide from "../ISlide";
import Text from '../../components/Text';
import {
  selectPersonalValues,
} from "../../features/counter/counterSlice";
import { alpha, styled } from '@mui/material/styles';
import {postUpdate} from '../../data';
import {StyledTextField} from '../../StyledComponents';
import PersonalValues from "./PersonalValues";

function ThinkWhy({setSlideOkToProceed, setSlideNotOkToProceed}: ISlide) {

  const personalValues = useAppSelector(selectPersonalValues);

  const onChange = (newVal:string) => {
    if (newVal.length) { 
      setSlideOkToProceed()
    } else {
      setSlideNotOkToProceed()
    }
  }

  return (
    <>
      <PersonalValues values={personalValues}/>
      {/* <div className="spacer"></div> */}
      <Text headline variant="h4">Now write a sentence or two about why these values are important to you. Feel free to write as much or as little as you like.</Text>
      {/* <div className="spacer"></div> */}
      {/* <TextField
        multiline
        rows={5}
        className="text-field"
        onBlur={(e)=> { postUpdate('ValuesAffirmation_Why', e.target.value) }}
        onChange={(e)=> { onChange(e.target.value) }}
      /> */}
      <StyledTextField
          multiline
          rows={5}
          className="black-text-field"
          onBlur={(e:any)=> { postUpdate('ValuesAffirmation_Why', e.target.value) }}
          onChange={(e:any)=> { onChange(e.target.value) }}
        />
    </>
  );
}

export default ThinkWhy;
