import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import ISlide from "../ISlide";
import Text from "../../components/Text";
import Grid from "@mui/material/Grid";
import {
  setPersonalValues,
  selectPersonalValues,
} from "../../features/counter/counterSlice";
import growingPlant from '../../images/growing_plant.svg';

function Intro() {
  
  return (
    <>
      
        <div className="headline-content">
          <Text variant="pageTitle">About You</Text>
        </div>
        <Text variant="h4">
          Learning more about patients - who they are and what they value - is important to us. 
        </Text>
        <img src={growingPlant}/>
        
    </>
  );
}

export default Intro;
