// import "./styles.css";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

interface IDropdown {
    img: any;
    isImgOnLeft: boolean;
    children: any;
}

export default function ImgLayout({img, isImgOnLeft, children}: IDropdown) {
    
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{height: '100%', minHeight: 0}}>
      {isImgOnLeft && <Grid item xs={6} className='img-grid vertically-center'>
        <a><img src={img}/></a>
      </Grid>}
      <Grid item xs={6} className='content-grid vertical-distribute'>
        {children}
      </Grid>
      {!isImgOnLeft && <Grid item xs={6} className='img-grid vertically-center'>
        <a><img src={img}/></a>
      </Grid>}
    </Grid>
  );
}
