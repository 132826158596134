import SlideNavigation from '../../navigation/SlideNavigation';
import ISection from "../ISection";
import "../../App.css";
import Text from '../../components/Text';
import champion from "../../images/champion.svg";
import CardWithTitle from '../../components/CardWithTitle';

  const headline = (
    <>
        <Text variant="pageTitle">If you choose to do the test, make a plan</Text>
        <Text variant="h4">New parents are busy! But making time for this important test can help you take control of your health.</Text>
    </>
  );
  
  const slide0 = (
    <>
      <Text variant="pageTitle">If you choose to do the test, make a plan</Text>
      <Text variant="h4">New parents are busy! But making time for this important test can help you take control of your health.</Text>
      <img src={champion} style={{maxHeight: '50%'}}/>
    </>
  );

  const slides = [
    {
      title: <div><b>When</b> you will do the test</div>,
      content: 'To save time, consider doing the test on the same day as your postpartum check-up.',
      backgroundColor: 'rgb(52 141 219)'
    },
    {
      title: <div><b>Who</b> will support you</div>,
      content: 'Could a family member or friend take you to the lab or help with the baby?',
      backgroundColor: 'rgb(91 176 187)'
    },
    {
      title: <div><b>What</b> will you do to remember</div>,
      content: 'It may help to put a reminder on your phone or calendar.',
      backgroundColor: 'rgb(245 124 103)'
    },
    {
      title: <div><b>How</b> will you overcome barriers</div>,
      content: 'Plan ahead to handle challenges that might get in your way.',
      backgroundColor: 'rgb(255 145 77)'
    },
    
  ]

function MakeAPlan({id, setSectionOkToProceed}: ISection) {

  return (
    <SlideNavigation
      id ={id}
      setSectionOkToProceed = {setSectionOkToProceed}
      //headlineContent={headline}
      //slidesArr = {[slide0, slide1, slide2, slide3, slide4]}
      slidesArr={[
        <div className="vertical-distribute">
          {(slide0)}
        </div>
        ,
        ...(slides).map(({title, content, backgroundColor}, i, arr) => (
        <>
          <Text variant="h3">Plan for… </Text>  <br/>
          <CardWithTitle
            title={(<Text variant="h3">{title}</Text>)}
            content={content}
            backgroundColor={backgroundColor}
          />
          <div className='spacer'/>
        </>
        
      ) )]}
    />
  );
}

export default MakeAPlan;
