import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Navigation from "./NavigationButtons";
import {
  gotoNextSection,
  gotoNextSlide,
  gotoPrevSection,
  gotoPrevSlide,
  getCurrentSlideIndex,
} from "./navigationUtils";
import ISlideNavigation from "./ISlideNavigation";
import ImgLayout from "../components/ImgLayout";
import "../App.css";

function SlideNavigation({
  id,
  slidesArr,
  headlineContent,
  darkText,
  sideImgUrl,
  isImgOnLeft,
}: ISlideNavigation) {
  //const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const clickNext = function () {
    gotoNextSlide();
  };

  const clickPrev = function () {
    gotoPrevSlide();
  };

  const slideContent = slidesArr.map((slide, i, arr) => (
    <div
      className={
        headlineContent ? "slide slide-with-headline-content" : "slide"
      }
      key={`EnhancedHealth${i}`}
    >
      <div className={"slide-inner"}>
        <div className="vertical-distribute">{slide}</div>
      </div>
    </div>
  ));

  return (
    <div
      key={"asd2"}
      className={darkText ? "section dark-text" : "section"}
      id={id}
    >
      <div className="section-inner height-remaining-nav">
        <div style={{ height: "100%" }}>
          <div className="vertical-distribute">
            {headlineContent && (
              <>
                
                  {headlineContent}
                
              </>
            )}

            {sideImgUrl ? (
              <ImgLayout
                img={sideImgUrl}
                isImgOnLeft={!!isImgOnLeft}
                children={slideContent}
              />
            ) : (
              <>
                {slideContent}
              </>
            )}
          </div>

          <Navigation
            goForward={clickNext}
            goBack={clickPrev}
            showHorizontalBack={getCurrentSlideIndex() != 0}
            showHorizontalForward={
              getCurrentSlideIndex() != slidesArr.length - 1
            }
            absolutePosition={true}
          />
        </div>
      </div>
    </div>
  );
}

export default SlideNavigation;
