import React, { useState, useEffect } from "react";
import CardSection from "../../card/CardSection";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ISection from '../ISection';
import Fab from "@mui/material/Fab";
import UndoIcon from "@mui/icons-material/Undo";
import "../../App.css";
import thirtyPercent from "../../images/tailored_risk_percent.svg";
import Text from '../../components/Text';

function FirstYearQuestion({id, setSectionOkToProceed}: ISection) {
  const [height, setHeight] = useState("");
  const [selectedHeightOption, selectHeightOption] = useState({} as any);
  //const heightValue = selectedHeightOption ? selectedHeightOption.value : 0;

  useEffect(() => {
    if (selectedHeightOption && selectedHeightOption.value == 3) {
      setSectionOkToProceed();
    }
  }, [selectedHeightOption]);

  const showFrontSide = !(selectedHeightOption && selectedHeightOption.response);

  return (
    // <ImgLayout
    //   img={img3}
    //   isImgOnLeft={false}
    // >
      <CardSection
        key='FirstYearQuestion'
        // minHeight = {306}
        sectionTitle="Testing is important for every patient who has had gestational diabetes."
        contentsFront={
          <div>
            <Text variant="h4" gutterBottom headline>Did you know?</Text>
            <Text>About ___ out of 10 patients with gestational diabetes will develop type 2 diabetes or pre-diabetes in the first year after delivery.</Text>
            <div style={{padding: '30px 0px'}} className="bmi-dropdown">
              <Text style={{minWidth: '200px'}} headline>Select an option:</Text>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={height}
                  label="Your height"
                  onChange={(e) => selectHeightOption( questionOptions.filter( o => o.value == (Number(e.target.value) || 0) )[0] )}
                  //hide if showing the other side because it can be clicked from the other side
                  style={{ minWidth: "150px", display: showFrontSide ? 'inherit' : 'none'}}
                >
                  {questionOptions.map(o => {return (
                    <MenuItem value={o.value}>{o.value}</MenuItem>
                  )})}
                </Select>
              </FormControl>
            </div>
            
          </div>
        }
        contentsBack={
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h2>{selectedHeightOption && selectedHeightOption.response}</h2>
            { selectedHeightOption && selectedHeightOption.value == 3 && <img src={thirtyPercent} style={{display: 'block', paddingTop: '20px', height: '20%', width: '20%', marginLeft: 'auto', marginRight: 'auto'}} />}
            { selectedHeightOption && selectedHeightOption.value != 3 &&
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={() => selectHeightOption(0)}
              className="card-button-undo"
              style={{marginTop:'35px', maxWidth: '150px'}}
            >
              <UndoIcon sx={{ mr: 1 }} />
              Change
            </Fab>}
          </div>
        }
        isFirstCard={true}
        isLastCard={true}
        isShowingCardFront={showFrontSide}
        
      />
    // </ImgLayout>
    
  );
}


const questionOptions = [
  {value: 0, response: 'That\'s not quite right. Give it another try.'},
  {value: 1, response: 'That\'s not quite right. Give it another try.'},
  {value: 2, response: 'That\'s not quite right. Give it another try.'},
  {value: 3, response: 'That\'s right. About 3 out of 10 patients will develop type 2 diabetes or pre-diabetes in the first year after delivery.'},
];

export default FirstYearQuestion;
