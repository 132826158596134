import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

interface IText {
    variant?: "pageTitle" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined;
    children: any;
    gutterBottom?: boolean;
    className?: string;
    style?: any;
    headline?: boolean;
}

let theme = createTheme();
theme = responsiveFontSizes(theme, {factor: 5});

export default function Text({variant, children, className, style, gutterBottom, headline}: IText) {

  return (
    <ThemeProvider theme={theme}>
        <Typography
            variant={variant == 'pageTitle' ? 'h3' : (variant || 'h5')}
            gutterBottom={!!gutterBottom}
            className={`sunrise-text ${variant ? 'text-' + variant : ''} ${className || ''} ${headline ? 'text-headline' : ''}`}
            style={style}
        >
            {children}
        </Typography>
    </ThemeProvider>
  );
}
