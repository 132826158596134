import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import TextField from "@mui/material/TextField";
import {
  setReasonToNotTest,
  setReasonToTest,
  setReasonToNotTestOther,
  setReasonToTestOther,
  selectReasonToTest,
  selectReasonToNotTest,
  selectReasonToTestOther,
  selectReasonToNotTestOther,
} from "../../features/counter/counterSlice";
import SlideNavigation from "../../navigation/SlideNavigation";
import ICardCollection from '../ICardCollection';
import Text from '../../components/Text';
import "../../card/card.scss";
import Dropdown from '../../components/Dropdown';
import {postUpdate} from '../../data';
import {StyledTextField} from '../../StyledComponents';


function WhatReasons({ id, setSectionOkToProceed, setSlideOkToProceed }: ICardCollection) {
  //const [selected, select] = useState([""] as string[]);
  const reasonToTest = useAppSelector(selectReasonToTest);
  const reasonToNotTest = useAppSelector(selectReasonToNotTest);
  const reasonToTestOther = useAppSelector(selectReasonToTestOther);
  const reasonToNotTestOther = useAppSelector(selectReasonToNotTestOther);
  const dispatch = useAppDispatch();

  const onChangeReasonToTest = (value: any, i: any) => {
    console.log('myvalue')
    dispatch(setReasonToTest(value as string));
    setSlideOkToProceed(id, 0);
    postUpdate("MI_ReasonToTest", (parseInt(i.key.substr(1)) + 1).toString());
  }

  const onChangeReasonToNotTest = (value: any, i: any) => {
    dispatch(setReasonToNotTest(value as string));
    setSlideOkToProceed(id, 1);
    postUpdate("MI_ReasonToNotTest", (parseInt(i.key.substr(1)) + 1).toString());
  }

  const onChangeReasonToTestOther = (value: any) => {
    dispatch(setReasonToTestOther(value as string));
    postUpdate("MI_ReasonToTest_Other", value);
  }

  const onChangeReasonToNotTestOther = (value: any) => {
    dispatch(setReasonToNotTestOther(value as string));
    postUpdate("MI_ReasonToNotTest_Other", value);
  }

  const intro = (
    <div>
      <div>
        <Text variant="h4">
          Many people have mixed feelings about testing for type 2 diabetes after
          delivery.
        </Text>
        <br/>
        <Text variant="h4">
          What are your most important reasons to do the test - and your reasons
          not to?
        </Text>
      </div>
      <div/>
    </div>
  );

  const slide1 = (
    <div>
      <Text variant="h4" gutterBottom>
        The most important reason I <span style={{fontWeight: 800, textDecoration: 'underline', textDecorationSkip: 'none', textDecorationSkipInk: 'none'}}>may</span> want to test for diabetes after delivery is to:
      </Text>
      <FormControl
        style={{ textAlign: "center", alignSelf: "center", minWidth: "90%" }}
      >
        
        <Dropdown
          value={reasonToTest}
          onChange={(e:any, i: any) => { 
            onChangeReasonToTest(e.target.value, i);
            if (e.target.value != 'Other (enter here)' && reasonToTestOther && reasonToTestOther.length) {
              onChangeReasonToTestOther('')
            }
          }}
          style={{ maxWidth: "400px", width: '90%', alignSelf: "center" }}
        >
          {reasonToTestOptions.map((o) => {
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
        </Dropdown>
        {reasonToTest == "Other (enter here)" && (
          <StyledTextField
            multiline
            rows={5}
            onBlur={(e:any) => {dispatch(setReasonToTestOther(e.target.value as string)); postUpdate("MI_ReasonToTest_Other", e.target.value)}}
          />
        )}
      </FormControl>
    </div>
  );

  const slide2 = (
    <div>
      <Text variant="h4" gutterBottom>
        The most important reason I may <span style={{fontWeight: 800, textDecoration: 'underline'}}>not</span> want to test for diabetes after
        delivery is because:
      </Text>
      <FormControl
        style={{ minWidth: "90%"}}
      >
        
        <Dropdown
          value={reasonToNotTest}
          onChange={(e:any, i: any) => {
            onChangeReasonToNotTest(e.target.value, i);
            if (e.target.value != 'Other (enter here)' && reasonToNotTestOther && reasonToNotTestOther.length) {
              onChangeReasonToNotTestOther('')
            }
          }}
          style={{ maxWidth: "400px", width: '90%', alignSelf: "center" }}
        >
          {reasonToNotTestOptions.map((o) => {
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
        </Dropdown>
        {reasonToNotTest == "Other (enter here)" && (
          <StyledTextField
            multiline
            rows={5}
            onBlur={(e:any) => {dispatch(setReasonToNotTestOther(e.target.value as string)); postUpdate("MI_ReasonToNotTest_Other", e.target.value)}}
          />
        )}
      </FormControl>
    </div>
  );

  return (
    <SlideNavigation
      id={id}
      setSectionOkToProceed={setSectionOkToProceed}
      headlineContent={intro}
      slidesArr={[slide1, slide2]}
      // sideImgUrl={image7}
      // isImgOnLeft={false}
    />
  );
}

export const reasonToTestOptions = [
  "Take care of my own health",
  "Find out if I have type 2 diabetes",
  "Prevent type 2 diabetes or its complications",
  "Stay healthy for my family",
  "Follow my doctor's recommendation",
  "Put my mind at ease",
  "Other (enter here)",
];

export const reasonToNotTestOptions = [
  "I will be more focused on caring for my newborn",
  "I am afraid to find out if I have type 2 diabetes",
  "I feel healthy. I'm not at high risk for type 2 diabetes",
  "It would be hard to find the time or find childcare",
  "I think most people skip this test",
  "It would be hard to fast for 10 hours",
  "Other (enter here)",
];

export default WhatReasons;
