import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {getUrlParams} from './utils';


function redirectIfUrlParametersNoLoaded() {
  const params = getUrlParams('u', 'm', 'cc', 'xp', 'admin', 'debug');
  if ((!params.u || !params.m || !params.cc || !params.xp) && window.location.search.indexOf("admin") == -1) {
    alert('Page not found. Perhaps we can help? Please contact the SUNRISE study at SUNRISE@kp.org or (866) 801-0020.\n\n Please click "Ok" to be directed to the KP Division of Research page.');
    document.location = 'https://divisionofresearch.kaiserpermanente.org/';
    return true;
  }
  return false;
}

const redirecting = redirectIfUrlParametersNoLoaded();

if (!redirecting) {
  const container = document.getElementById('root')!;
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
  reportWebVitals();
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

