import React, { useState } from "react";
import ICardCollection from '../ICardCollection';
import CardSection from "../../card/CardSection";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ISection from "../ISection";
import Fab from "@mui/material/Fab";
import UndoIcon from "@mui/icons-material/Undo";
import "../../App.css";
import Text from '../../components/Text';
import {postUpdate} from '../../data';
import SlideNavigation from '../../navigation/SlideNavigation';
import check from "../../images/check.svg";

function HeightBMI({ id, setSectionOkToProceed, setSlideOkToProceed }: ICardCollection) {
  //const [height, setHeight] = useState("");
  const [isAsianAmerican, setIsAsianAmerican] = useState("");
  const [selectedHeightOption, selectHeightOption] = useState({} as any);
  //const heightValue = selectedHeightOption ? selectedHeightOption.value : 0;

  React.useEffect(() => {
    if (Object.keys(selectedHeightOption).length != 0 && isAsianAmerican != "") {
      setSectionOkToProceed(id, true);
    }
  }, [isAsianAmerican, selectedHeightOption]);

  const onChangeVal = (newVal: any) => {
    if (newVal && ((isAsianAmerican && isAsianAmerican.length) || (selectedHeightOption && Object.keys(selectedHeightOption).length))) {
      setSlideOkToProceed(id, 1);
    }
  }
  const onChangeIsAsianAmerican = (value: any) => {
    postUpdate("TailoredRisk_AsianAmerican", value == "Yes" ? '1' : '0');
    setIsAsianAmerican( value );
    onChangeVal(value);
  }

  const onChangeHeight = (value: any) => {
    //postUpdate("TailoredRisk_Height", value);
    const index = heightOptions.findIndex( (o) => o.value == (Number(value) || 0) );
    postUpdate("TailoredRisk_Height", (index + 1).toString());
    selectHeightOption( heightOptions.filter( o => o.value == (Number(value) || 0) )[0] );
    onChangeVal(value);
  }

  const showFrontSide = !(selectedHeightOption && selectedHeightOption.value && isAsianAmerican != "");

  const cardSectionSlide = (
    <>
      <Text variant="pageTitle">Let's check if this is true for you.</Text>
      <CardSection
        minHeight = {380}
        contentsFront={
          <div>
            {/* <Text>
              <ul className='sunriseUl'>
                <li>
                  Weight is just one of many aspects of health.
                </li>
                <li>People with a higher body weight for their height are at higher risk for type 2 diabetes.</li>
              </ul>
            </Text> */}
            <div className="bmi-dropdown">
              <div style={{textAlign: 'left', maxWidth: '60%'}}>
                <Text headline style={{paddingRight: '20px'}}>How tall are you?</Text>
              </div>
              <FormControl>
                <Select
                  id="height"
                  value={selectedHeightOption && selectedHeightOption.value ? selectedHeightOption.value : ''}
                  onChange={(e) => { onChangeHeight(e.target.value); }}
                  //hide if showing the other side because it can be clicked from the other side
                  style={{ minWidth: "150px", display: showFrontSide ? 'inherit' : 'none' }}
                >
                  {heightOptions.map(o => {return (
                    <MenuItem value={o.value}>{o.title}</MenuItem>
                  )})}
                </Select>
              </FormControl>
            </div>
            <div className="bmi-dropdown">
              <div style={{textAlign: 'left', maxWidth: '60%'}}>
                <Text headline style={{paddingRight: '20px'}}>Are you Asian American*?</Text>
              </div>
              <FormControl>
                <Select
                  id="isAsianAmerican"
                  value={isAsianAmerican}
                  onChange={(e) => { onChangeIsAsianAmerican(e.target.value); }}
                  //hide if showing the other side because it can be clicked from the other side
                  style={{ minWidth: "150px", display: showFrontSide ? 'inherit' : 'none' }}
                >
                  <MenuItem value={'No'}>No</MenuItem>
                  <MenuItem value={'Yes'}>Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Text variant="subtitle1">*People who are Asian American face higher risk for type 2 diabetes at a lower weight. Asian Americans are a diverse group and some, such as South Asian and Filipino people, face especially high risk.</Text>
          </div>
        }
        contentsBack={
          <div className="vertical-distribute">
            <Text>Based on your height, you are at higher risk for type 2 diabetes if your weight before pregnancy was:</Text>
            
            <span style={{backgroundColor: 'white', padding: '10px', borderRadius: '7px' }}>
              <Text>
                {selectedHeightOption && (isAsianAmerican == 'Yes' ? selectedHeightOption.bmiAsian : selectedHeightOption.bmiNotAsian)} or more.
                {/* {isAsianAmerican  == 'Yes'&& <span>*</span>} */}
              </Text>
            </span>
            
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={() => {selectHeightOption({}); setIsAsianAmerican(""); }}
              className="card-button-undo"
              style={{marginTop:'1em', maxWidth: '150px'}}
            >
              <UndoIcon sx={{ mr: 1 }} />
              Change
            </Fab>
          </div>
        }
        gotoNextSlide={"any"}
        gotoPrevSlide={"any"}
        isFirstCard={true}
        isLastCard={true}
        gotoNextSection={"any"}
        isShowingCardFront={showFrontSide}
      />
    </>
  );

  const slide1 = (
    <>
      <Text variant="pageTitle">
        Having a higher body weight for your height is also a personal risk factor.
      </Text>
      <Text variant="h4">
        <ul style={{textAlign: 'left'}}>
          <li>Weight is just one of many aspects of health.</li>
          <li>People with a higher body weight for their height are at higher risk for type 2 diabetes.</li>
        </ul>
      </Text>
      
    </>
  );

  const slide3 = (
    <>
      <Text variant="pageTitle">
        Here's the important part:
      </Text>
      <Text variant="h4">
        <p>No matter how many risk factors you have, <b>you can do a lot to prevent or delay type 2 diabetes and its complications.</b></p>
        <p>The first step is to test for diabetes after delivery.</p>
      </Text>
      <img src={check} style={{maxHeight: '25%'}}/>
    </>
  );

  return (
    <SlideNavigation
    id ={id}
    setSectionOkToProceed = {setSectionOkToProceed}
    // headlineContent={headline}
    slidesArr = {[slide1, cardSectionSlide, slide3]}
    // sideImgUrl={image9}
    // isImgOnLeft={true}
  />
  );
}


const heightOptions = [
  {value: 147, title: '4\'10" (147 cm)', bmiAsian: '110 lbs. (50 kg)', bmiNotAsian: '119 lbs. (54 kg)'},
  {value: 150, title: '4\'11" (150 cm)', bmiAsian: '114 lbs. (51 kg)', bmiNotAsian: '124 lbs. (56 kg)'},
  {value: 152, title: '5\'0" (152 cm)', bmiAsian: '118 lbs. (54 kg)', bmiNotAsian: '128 lbs. (58 kg)'},
  {value: 155, title: '5\'1" (155 cm)', bmiAsian: '122 lbs. (55 kg)', bmiNotAsian: '132 lbs. (60 kg)'},
  {value: 157, title: '5\'2" (157 cm)', bmiAsian: '126 lbs. (57 kg)', bmiNotAsian: '136 lbs. (62 kg)'},
  {value: 160, title: '5\'3" (160 cm)', bmiAsian: '130 lbs. (59 kg)', bmiNotAsian: '141 lbs. (64 kg)'},
  {value: 163, title: '5\'4" (163 cm)', bmiAsian: '134 lbs. (61 kg)', bmiNotAsian: '145 lbs. (66 kg)'},
  {value: 165, title: '5\'5" (165 cm)', bmiAsian: '138 lbs. (63 kg)', bmiNotAsian: '150 lbs. (68 kg)'},
  {value: 168, title: '5\'6" (168 cm)', bmiAsian: '142 lbs. (64 kg)', bmiNotAsian: '155 lbs. (70 kg)'},
  {value: 170, title: '5\'7" (170 cm)', bmiAsian: '146 lbs. (66 kg)', bmiNotAsian: '159 lbs. (72 kg)'},
  {value: 173, title: '5\'8" (173 cm)', bmiAsian: '151 lbs. (68 kg)', bmiNotAsian: '164 lbs. (74 kg)'},
  {value: 175, title: '5\'9" (175 cm)', bmiAsian: '155 lbs. (70 kg)', bmiNotAsian: '169 lbs. (77 kg)'},
  {value: 178, title: '5\'10" (178 cm)', bmiAsian: '160 lbs. (73 kg)', bmiNotAsian: '174 lbs. (79 kg)'},
  {value: 180, title: '5\'11" (180 cm)', bmiAsian: '165 lbs. (75 kg)', bmiNotAsian: '179 lbs. (82 kg)'},
  {value: 183, title: '6\'0" (183 cm)', bmiAsian: '169 lbs. (77 kg)', bmiNotAsian: '184 lbs. (83 kg)'},
  {value: 185, title: '6\'1" (185 cm)', bmiAsian: '174 lbs. (79 kg)', bmiNotAsian: '189 lbs. (86 kg)'},
  {value: 188, title: '6\'2" (188 cm)', bmiAsian: '179 lbs. (82 kg)', bmiNotAsian: '194 lbs. (88 kg)'},
];

export default HeightBMI;
