import React, { useState, useEffect } from "react";
import "../../App.css";
//import sunriseLogo from "../../images/sunrise-logo.png";
import sunriseLogo from "../../images/sunrise.svg";
import kpLogo from "../../images/kpresearchlogo-big-blue.png";
import Text from "../../components/Text";
import { alpha, styled } from "@mui/material/styles";


const SunriseImg = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '20%',
  [theme.breakpoints.down('md')]: {
  
  },
  [theme.breakpoints.down('sm')]: {
    height: '10%',
  }
}));

function Welcome() {
  return (

    <div id="WelcomeSection">
      <div id="logotTop" className="section-inner-custom">
        <div
          className="row"
          style={{ paddingTop: "15px", paddingLeft: "15px" }}
        >
          <div className="col-sm-9 col-md-8">
            <img className="navbar-brand" src={kpLogo} />
            <span id="studyName">SUNRISE</span>
          </div>

          <div className="col-sm-1 col-md-2">
            <p></p>
          </div>
          <div className="col-sm-3 col-md-2"></div>
        </div>
      </div>
      <div style={{ paddingTop: "58px", height: "calc(100% - 58px)", display: "block", marginLeft: "auto", marginRight: "auto" }}>
        <div className="vertical-distribute">
          <SunriseImg src={sunriseLogo} style={{ maxWidth: '100%', maxHeight: '20%' }} />
          <Text
            variant="h1"
            style={{ paddingTop: "20px", fontWeight: 800, }}
          >
            Welcome
          </Text>

          <Text>
            The Regional Perinatal Service Center is working with the Kaiser Permanente Division of
            Research and University of California, Davis to support your health.
          </Text>
          <Text>
            We're pleased to share this important message with you. It
            should take about 15 minutes.
          </Text>
          <Text>
            Later, please give us your
            honest feedback about it. You'll receive a $20 gift card for your time.
          </Text>
          <Text variant="caption">
            This information will only be used for research. To
            keep it confidential, do not to enter information that
            could identify you (like your name or birthdate). Questions?
            Contact study staff at{" "}
            <a href="mailto:SUNRISE@kp.org">SUNRISE@kp.org</a>.
          </Text>
          {/* </ImgLayout> */}
        </div>
      </div>

    </div>
  );
}

export default Welcome;
