import React, { useState, useEffect } from "react";
import "../../App.css";
import ICardCollection from "../ICardCollection";
import Text from "../../components/Text";
import SlideNavigation from "../../navigation/SlideNavigation";
import image5 from "../../images/img5.svg";

function Quotes({ id, setSectionOkToProceed }: ICardCollection) {
  const slide1 = (
    <div className="vertical-distribute">
      <Text variant="h3">
        Staying healthy for myself and my family
      </Text>
      <Text
        variant="h4"
        className="example-text quote-block"
        style={{
          backgroundColor: "rgba(247, 172, 50, 0.8)",
          padding: "25px",
          borderRadius: "35px",
        }}
      >
        "You are important… Don't ignore yourself… You need to be strong because
        if the foundation is strong, the entire family runs."
        <br />
        <br />
        -Woman with gestational diabetes
      </Text>
    </div>
  );

  const slide2 = (
    <div className="vertical-distribute">
      <Text variant="h3">
        Finding out if I have diabetes
      </Text>
      <Text
        variant="h4"
        className="example-text quote-block"
        style={{
          backgroundColor: "rgba(247, 172, 50, 0.8)",
          padding: "25px",
          borderRadius: "35px",
        }}
      >
        "…let's just put the facts on the table… Let's work on it."
        <br />
        <br />
        -Woman with gestational diabetes
      </Text>
    </div>
  );

  return (
    <SlideNavigation
      id = {id}
      headlineContent = {(<Text variant="pageTitle">Some of the most common reasons to do the test include...</Text>)}
      setSectionOkToProceed={setSectionOkToProceed}
      // sideImgUrl={image5}
      // isImgOnLeft={true}
      slidesArr={[slide1, slide2]}
    />
  );
}

export default Quotes;
