import "../../App.css";
import Text from '../../components/Text';
import logo from '../../images/sunrise_logo_title.svg';
import {getUrlParam} from '../../utils';

function Closing() {
  const surveyUrlExtension = getUrlParam('s');
  const surveyUrl = surveyUrlExtension ? "https://redcap.kaiser.org/surveys/?s=" + surveyUrlExtension : "#";

  const onClickSurvey = () => {
    if (!surveyUrlExtension) {
      alert('REDCap survey URL is not loaded. Please ensure that the "surv" parameter is included in the URL.');
    }
  }
  
  return (
  <div>
    <div className="vertical-distribute">
      <Text variant="pageTitle">
        Now for your honest feedback
      </Text>
      <Text>Please click <b><a onClick={onClickSurvey} href={surveyUrl}>here</a></b> to rate the health message you just read.</Text>
      <Text>You'll receive a $20 gift card to thank you for your feedback.</Text>
      <Text variant="h4">Thank you!</Text>
      <img src={logo} style={{maxHeight: '25%'}}/>
    </div>
  </div>
  );
}

export default Closing;
