import React, { useState } from "react";
import ICard from './ICard';
import Card from './Card';
import Text from '../components/Text';


import "./card.scss";


function App(props: ICard) {
    
  return (
    <div className="inner-slide">
      <div className="page-container vertical-distribute">
        <Text variant="pageTitle" className="card-page-title">{props.sectionTitle}</Text>
        
        <Card {...props} />
        {(props.Footer)}
        
      </div>
    </div>
  );
}



export default App;
