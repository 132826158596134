import React, { useState } from "react";
import Intro from "./ValuesAffirmationIntro";
import AboutYou from "./AboutYou";
import ThinkWhy from "./ThinkWhy";
import WriteWhy from "./WriteWhy";
import HowMuchAgree from "./HowMuchAgree";
import {
  gotoNextSection,
  gotoNextSlide,
  gotoPrevSection,
  gotoPrevSlide,
  getCurrentSlideIndex,
} from "../../navigation/navigationUtils";
import "../../card/card.scss";
import SlideNavigation from "../../navigation/SlideNavigation";

import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { selectPersonalValues } from "../../features/counter/counterSlice";
import ICardCollection from "../ICardCollection";

function ValuesAffirmationSlides({
  setSectionOkToProceed,
  setSlideOkToProceed,
  id
}: ICardCollection) {
  const personalValues = useAppSelector(selectPersonalValues);

  // const isOkToProceed = () => {};

  // const clickNextSlide = (i: number, arr: any) => {
  //   if (i == arr.length - 2) {
  //     setSectionOkToProceed();
  //   }
  //   gotoNextSlide();
  // };

  return (
    <SlideNavigation
      slidesArr={[
        <Intro/>,
        <AboutYou
          setSlideOkToProceed={() => setSlideOkToProceed(id, 1)}
          setSlideNotOkToProceed={() => setSlideOkToProceed(id, 1, false)}
        />,
        <ThinkWhy />,
        <WriteWhy
          setSlideOkToProceed={() => setSlideOkToProceed(id, 3)} 
          setSlideNotOkToProceed={() => setSlideOkToProceed(id, 3, false)}
        />,
        <HowMuchAgree 
          setSlideOkToProceed={() => {setSectionOkToProceed(); setSlideOkToProceed(id, 4);}} 
          setSlideNotOkToProceed={() => setSlideOkToProceed(id, 4, false)}
        />,
      ]}
      setSectionOkToProceed={setSectionOkToProceed}
      setSlideOkToProceed={setSlideOkToProceed}
      haltProceed={!(personalValues?.length > 1)}
      darkText
      id={id}
    />
  );
}

export default ValuesAffirmationSlides;
