export function getUrlParams(...params: string[]) {
    const urlParams = new URLSearchParams(window.location.search);
    let returnObj:any = {};
    for (let o of params) {
        returnObj[o] = urlParams.get(o);
    }
    return returnObj;
}

export function getUrlParam(param: string) {
    return getUrlParams(param)[param];
}