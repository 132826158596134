import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { fetchCount } from "./counterAPI";
import { getSectionsFilteredByOkToProceed } from "../../SectionsContent";

export interface CounterState {
  value: number;
  status: "idle" | "loading" | "failed";
  personalValues: string[];
  reasonToTest: string;
  reasonToNotTest: string;
  reasonToTestOther: string;
  reasonToNotTestOther: string;
  sectionsViewable: any[];
  slidesOkToProceed: any;
  slidesOked: any;
}

const initialState: CounterState = {
  value: 0,
  status: "idle",
  personalValues: [],
  reasonToTest: "",
  reasonToNotTest: "",
  reasonToTestOther: "",
  reasonToNotTestOther: "",
  //sectionsViewable: [0]
  sectionsViewable: [0],
  slidesOkToProceed: {
    0: [0],
  },
  slidesOked: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  "counter/fetchCount",
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPersonalValues: (state, action: PayloadAction<string[]>) => {
      state.personalValues = action.payload;
    },
    setReasonToTest: (state, action: PayloadAction<string>) => {
      state.reasonToTest = action.payload;
    },
    setReasonToNotTest: (state, action: PayloadAction<string>) => {
      state.reasonToNotTest = action.payload;
    },
    setReasonToTestOther: (state, action: PayloadAction<string>) => {
      state.reasonToTestOther = action.payload;
    },
    setReasonToNotTestOther: (state, action: PayloadAction<string>) => {
      state.reasonToNotTestOther = action.payload;
    },
    setSectionsViewable: (state, action: PayloadAction<any[]>) => {
      state.sectionsViewable = action.payload;
    },
    addSlideOkToProceed: (
      state,
      action: PayloadAction<{ sectionIndex: number; slideId: number }>
    ) => {
      // let newObj = {} as any;
      // newObj[action.payload.sectionId][action.payload.slideId].Oked = true;
      // state.slidesOkToProceed = {...state.slidesOkToProceed, ...newObj };
      state.slidesOkToProceed = {
        ...state.slidesOkToProceed,
        [action.payload.sectionIndex]: [
          ...(state.slidesOkToProceed[action.payload.sectionIndex] || []),
          action.payload.slideId,
        ],
      };
      console.log('state.slidesOkToProceed');
      console.log(state.slidesOkToProceed);
    },
    removeSlideOkToProceed: (
      state,
      action: PayloadAction<{ sectionIndex: number; slideId: number }>
    ) => {
      state.slidesOkToProceed = {
        ...state.slidesOkToProceed,
        [action.payload.sectionIndex]: (state.slidesOkToProceed[action.payload.sectionIndex] || []).filter((o: number) => o != action.payload.slideId),
      };
      console.log('state.slidesOkToProceed');
      console.log(state.slidesOkToProceed);
    },
    setSlideErrorOked: (
      state,
      action: PayloadAction<{ sectionIndex: number; slideId: number }>
    ) => {
      // let newObj = {} as any;
      // newObj[action.payload.sectionId][action.payload.slideId].Oked = true;
      // state.slidesOked = {...state.slidesOkToProceed, ...newObj };
      state.slidesOked = {
        ...state.slidesOked,
        [action.payload.sectionIndex]: [
          ...(state.slidesOked[action.payload.sectionIndex] || []),
          action.payload.slideId
        ],
      };
    },
    addSectionsViewable: (state, action: PayloadAction<number>) => {
      const newArr = [
        ...state.sectionsViewable?.map((o, i) => i),
        action.payload,
      ];
      const sections = getSectionsFilteredByOkToProceed(newArr, () => {});
      state.sectionsViewable = sections;
    },

    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value += action.payload;
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  increment,
  decrement,
  incrementByAmount,
  setPersonalValues,
  setReasonToNotTest,
  setReasonToTest,
  addSlideOkToProceed,
  removeSlideOkToProceed,
  setReasonToNotTestOther,
  setReasonToTestOther,
  setSectionsViewable,
  addSectionsViewable,
  setSlideErrorOked,
} = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.counter.value;

export const selectPersonalValues = (state: RootState) =>
  state.counter.personalValues;
export const selectReasonToTest = (state: RootState) =>
  state.counter.reasonToTest;
export const selectReasonToNotTest = (state: RootState) =>
  state.counter.reasonToNotTest;
export const selectReasonToTestOther = (state: RootState) =>
  state.counter.reasonToTestOther;
export const selectReasonToNotTestOther = (state: RootState) =>
  state.counter.reasonToNotTestOther;
export const selectSectionsViewable = (state: RootState) =>
  state.counter.sectionsViewable;
export const selectSlidesOkToProceed = (state: RootState) =>
  state.counter.slidesOkToProceed;
export const selectSlidesOked = (state: RootState) => state.counter.slidesOked;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  };

export default counterSlice.reducer;
