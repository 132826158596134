import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SlideNavigation from "../../navigation/SlideNavigation";
import ISection from "../ISection";
import ChipHeadline from "./ChipHeadline";
import "../../App.css";
import { StyledTextField } from "../../StyledComponents";
import Text from "../../components/Text";
import { postUpdate } from "../../data";

const MyPlan = ({
  setSlideOkToProceed,
  setSlideNotOkToProceed,
  postUpdateTarget,
  setVal,
}: {
  setSlideOkToProceed: any;
  setSlideNotOkToProceed: any;
  postUpdateTarget: any;
  setVal: any;
}) => (
  <StyledTextField
    multiline
    rows={5}
    onChange={(e:any) => {
      if (e.target.value.length > 0) {
        setSlideOkToProceed();
        setVal(e.target.value);
      } else {
        setSlideNotOkToProceed();
        setVal("");
      }
      return e;
    }}
    onBlur={(e:any) => postUpdate(postUpdateTarget, e.target.value)}
  />
);

const ExampleText = ({ text }: { text: string }) => (
  <Text className="chip-subtext">
    <b>Example: </b>
    <span className="example-text">{text}</span>
  </Text>
);

const headline = (
  <>
    <Text variant="pageTitle">Your turn</Text>
  </>
);


function MakeAPlan({
  id,
  setSectionOkToProceed,
  setSlideOkToProceed,
  setSlideNotOkToProceed,
}: ISection) {
  const [slideInput1, setSlideInput1] = useState("");
  const [slideInput2, setSlideInput2] = useState("");
  const [slideInput3, setSlideInput3] = useState("");
  const [slideInput4, setSlideInput4] = useState("");

  const qa = [
    {
      question: (
        <div>
          <b>When</b> I will take the test:
        </div>
      ),
      answer: slideInput1,
      color: 'rgb(52 141 219)',
      postUpdateTarget: "ActionPlanning_When",
      setVal: setSlideInput1,
      exampleText:
        "8 a.m. on Jan. 15th, 6 weeks after my due date. I'll book my postpartum check-up for that same morning.",
    },
    {
      question: (
        <div>
          <b>Who</b> will support me:
        </div>
      ),
      answer: slideInput2,
      color: 'rgb(91 176 187)',
      postUpdateTarget: "ActionPlanning_Who",
      setVal: setSlideInput2,
      exampleText:
        "My mom could drive me there and hold the baby during my appointments.",
    },
    {
      question: (
        <div>
          <b>What</b> I will do to remember:
        </div>
      ),
      answer: slideInput3,
      color: 'rgb(245 124 103)',
      postUpdateTarget: "ActionPlanning_What",
      setVal: setSlideInput3,
      exampleText: "I'll put reminders on my phone and kitchen calendar.",
    },
    {
      question: (
        <div>
          <b>How</b> I will overcome barriers:
        </div>
      ),
      answer: slideInput4,
      color: 'rgb(255 145 77)',
      postUpdateTarget: "ActionPlanning_How",
      setVal: setSlideInput4,
      exampleText:
        "I'll pack healthy snacks to eat right after the test is done. I'll do the test during parental leave, so I won't have to take extra time off work.",
    },
  ];

  const slides = qa.map((o, i) => (
    <>
      <Text>Now enter your plan. Check out the examples for ideas.</Text>
      <ChipHeadline text={o.question} color={o.color} />
      <MyPlan
        setSlideOkToProceed={() => {
          setSlideOkToProceed(id, i);
        }}
        setSlideNotOkToProceed={() => {
          setSlideOkToProceed(id, i, false);
        }}
        postUpdateTarget={o.postUpdateTarget}
        setVal={o.setVal}
      />
      <ExampleText text={o.exampleText} />
    </>
  ));

  const slide5 = (
    <>
      <Text>Here is the plan you created:</Text>
      {qa.map((o) => (
        <>
          <ChipHeadline text={o.question} color={o.color} isSmall />
          <Text>{o.answer}</Text>
        </>
      ))}
    </>
  );

  return (
    <SlideNavigation
      id={id}
      setSectionOkToProceed={setSectionOkToProceed}
      headlineContent={headline}
      // slidesArr = {[slide1, slide2, slide3, slide4, slide5]}
      slidesArr={[...slides, slide5]}
    />
  );
}

export default MakeAPlan;
