import React, { useState } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import UndoIcon from "@mui/icons-material/Undo";
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Text from '../components/Text';
import {postUpdate} from '../data';
import { alpha, styled } from "@mui/material/styles";
import "./card.scss";

const NavButton = styled(Fab)(({ theme }) => ({
  fontSize: '9.5pt',
  margin: '0px 12px',
  float: 'right',
  paddingLeft: '15px',
  [theme.breakpoints.down('lg')]: {
    // fontSize: '14pt',
    margin: '0px 10px',
  },
  [theme.breakpoints.down('md')]: {
    // fontSize: '12pt',
    margin: '0px 7px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '8pt',
    padding: '15px',
  }
}));

const NavButtonContainer = styled(Box)(({ theme }) => ({
  width: "60%",
  height: '66px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: "67%",
  },
  [theme.breakpoints.down('md')]: {
    width: "75%",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
    height: '48px',
  }
}));

const NavButtonContainerInner = styled(Box)(({ theme }) => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('lg')]: {
    
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 'auto'
  }
}));



const whiteTheme = createTheme({
  palette: {
    primary: {
      main: '#FFF',
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});


interface ICard {
  textFront: string;
  textBack: string;
  gotoNextSlide: any;
  gotoPrevSlide: any;
  isFirstCard?: boolean;
  isLastCard?: boolean;
  gotoNextSection?: any;
  postResponse: string;
}

interface IControlButtons {
  gotoNextSlide: any;
  gotoPrevSlide: any;
  isShowingCardFront: boolean;
  flip: any;
  isFirstCard?: boolean;
  isLastCard?: boolean;
  gotoNextSection: any;
  postResponse: string;
}

interface IBlogCard {
    textFront: string;
    textBack: string;
    gotoNextSlide: any;
    gotoPrevSlide: any;
    isShowingCardFront: boolean;
    postResponse: string;
}

function App(props: ICard) {
    const [isShowingCardFront, setShowingCardFront] = useState(true);
    const flip: any = () => {
        setShowingCardFront(!isShowingCardFront);
      };
    const blogCardProps = {...props, isShowingCardFront: isShowingCardFront};

  return (
    <div className="inner-slide">
      <div className="page-container">
        
        
        {/* <h3>In addition to gestational diabetes, there are other personal risk factors for type 2 diabetes</h3> */}
        <BlogCard {...blogCardProps} />
        <ControlButtons
          gotoNextSlide={props.gotoNextSlide}
          gotoPrevSlide={props.gotoPrevSlide}
          gotoNextSection={props.gotoNextSection}
          flip={flip}
          isShowingCardFront={isShowingCardFront}
          isFirstCard={props.isFirstCard}
          isLastCard={props.isLastCard}
          postResponse={props.postResponse}
        />
      </div>
    </div>
  );
}



function ControlButtons(props: IControlButtons) {

  const clickYesNo = (isLastCard: boolean, question: any, clickedYes: boolean) => {
    if (clickedYes) {
      props.flip();
    } else {
      if (props.isLastCard) {
        props.gotoNextSection();
      } else {
        props.gotoNextSlide();
      }
  }
    postUpdate(question, clickedYes ? '1' : '0');
  }

  return (
    <NavButtonContainer
      className="slide-control-button-row"
      sx={{ "& > :not(style)": { m: 1 } }}
    >
      
      <NavButtonContainerInner className='slide-control-button-row-inner' style={{opacity: props.isShowingCardFront ? 1 : 0, display: props.isShowingCardFront ? 'block' : 'none', visibility: props.isShowingCardFront ? 'visible' : 'hidden'}}>
          {/* <ThemeProvider theme={whiteTheme}> */}
            <NavButton
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={props.gotoPrevSlide}
              className="card-button-back-front"
              //style={{display: props.isFirstCard ? 'none' : 'inline-flex'}}
            >
              {props.isFirstCard ? <ArrowUpward sx={{ mr: 1 }} /> : <ArrowBack sx={{ mr: 1 }} /> }
              Back
            </NavButton>
          {/* </ThemeProvider> */}

          <NavButton
            variant="extended"
            size="medium"
            color="success"
            aria-label="add"
            onClick={ () => {clickYesNo(!!props.isLastCard, props.postResponse, true);} }
            // style={{marginLeft: '25px'}}
            className="card-button-yes"
          >
            <CheckIcon sx={{ mr: 1 }} />
            Yes
          </NavButton>

          <NavButton
            variant="extended"
            size="medium"
            color="error"
            aria-label="add"
            onClick={ () => clickYesNo(!!props.isLastCard, props.postResponse, false) }
            // style={{marginLeft: '155px'}}
            className="card-button-no"
          >
            <CloseIcon sx={{ mr: 1 }} />
            No
          </NavButton>
          
        </NavButtonContainerInner>
      

      
        <NavButtonContainerInner className='slide-control-button-row-inner' style={{opacity: props.isShowingCardFront ? 0 : 1, display: props.isShowingCardFront ? 'none' : 'block', visibility: props.isShowingCardFront ? 'hidden' : 'visible'}}>
          <ThemeProvider theme={whiteTheme}>
          <NavButton
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={props.flip}
            className="card-button-undo"
          >
            <UndoIcon sx={{ mr: 1 }} />
            Undo
          </NavButton>
          </ThemeProvider>

          <NavButton
            variant="extended"
            size="medium"
            color="success"
            aria-label="add"
            onClick={props.isLastCard ? props.gotoNextSection : props.gotoNextSlide}
            className="card-button-next"
          >
            <ArrowForward sx={{ mr: 1 }} />
            Next
          </NavButton>

          <NavButton
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={props.gotoPrevSlide}
              className="card-button-back"
              //style={{display: props.isFirstCard ? 'none' : 'inline-flex'}}
            >
              <ArrowBack sx={{ mr: 1 }} />
              Back
            </NavButton>
        </NavButtonContainerInner>
      
    </NavButtonContainer>
  );
}


function BlogCard(props: IBlogCard) {
  return (
    <div className={"card-container" + (!props.isShowingCardFront ? " flipped" : "")}>
      <Front {...props} />
      <Back {...props} />
    </div>
  );
}

function Front(props: ICard) {
  return (
    <div className="front">
      <Text variant="h4">{props.textFront}</Text>
    </div>
  );
}

function Back(props: ICard) {
  return (
    <div className="back">
      {/* <h1 className='underline'>{props.textFront}</h1> */}
      <Text variant="h4">{props.textBack}</Text>
    </div>
  );
}

export default App;
