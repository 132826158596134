import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import {
  selectReasonToTest,
  selectReasonToTestOther,
  selectReasonToNotTest,
  selectReasonToNotTestOther,
} from "../../features/counter/counterSlice";
import ICardCollection from "../ICardCollection";
import {StyledTextField} from '../../StyledComponents';
import Text from '../../components/Text';
import ImgLayout from '../../components/ImgLayout';
import image2 from '../../images/img2.svg';
import {postUpdate} from '../../data';
import SlideNavigation from "../../navigation/SlideNavigation";

function WhatReasons({id, setSectionOkToProceed, setSlideOkToProceed}: ICardCollection) {
  const [myPlan, setMyPlan] = useState("");
  const reasonToTest = useAppSelector(selectReasonToTest);
  const reasonToNotTest = useAppSelector(selectReasonToNotTest);
  const reasonToTestOther = useAppSelector(selectReasonToTestOther);
  const reasonToNotTestOther = useAppSelector(selectReasonToNotTestOther);
  const dispatch = useAppDispatch();

  const onChange = (value: any) => {
    setSlideOkToProceed(id, 1);
    postUpdate('MI_Response', value);
    return value;
  }

  const lowercaseFormatResponse = (text: string) => {
    text = text.trim();
    if (text.length >= 2) {
      //lowercase first letter if it's not 'I'
      let twoLetters = text.substr(0, 2);
      if (twoLetters != 'I ') {
        twoLetters = twoLetters.toLocaleLowerCase();
      }
      text = twoLetters + text.substring(2);

      //remove last character if it's a punctuation mark.
      let lastCharacter = text.substring(text.length - 1, text.length);
      if ( lastCharacter == '.' || lastCharacter == '?' || lastCharacter == ';' || lastCharacter == '!' ) {
        text = text.substring(0,text.length - 1);
      }
    }
    return text;
  }


  const slide1 = (
    <div className='vertical-distribute'>
        <Text variant="pageTitle" headline>Here's what you've told us so far:</Text>
        <Text variant="h4">I may want to test for diabetes after delivery to {lowercaseFormatResponse( reasonToTestOther != '' ? reasonToTestOther : reasonToTest )}.</Text>
        <Text variant="h4">At the same time, I may not want to test because {lowercaseFormatResponse( reasonToNotTestOther != '' ? reasonToNotTestOther : reasonToNotTest )}.</Text>
    </div>
  );

  const slide2 = (
    <div className='vertical-distribute'>
        <Text variant="pageTitle" headline>Where does that leave you now?</Text>
        
        <Text>Example: 
          <span style={{fontStyle: 'italic'}}>It's hard to make time for this, but it will help me stay healthy for myself and my family.</span>
        </Text>
        <Text headline>Your response:</Text>
        <StyledTextField
            multiline
            rows={5}
            className="text-field"
            onBlur={(e:any) => onChange(e.target.value) }  
            style={{width: '90%'}}
          />
      
      <Text headline>
        Ultimately, the choice is yours.
      </Text>
    </div>
  );

  return (
    <SlideNavigation
      id = {id}
      setSectionOkToProceed={setSectionOkToProceed}
      // sideImgUrl={image2}
      // isImgOnLeft={false}
      slidesArr={[slide1, slide2]}
      //verticalDistribute
    />
      
  );
}

export default WhatReasons;
