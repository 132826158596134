import React, { useState } from "react";
import Card from "../../card/CardYesNo";
import {gotoNextSection, gotoNextSlide, gotoPrevSection, gotoPrevSlide, getCurrentSlideIndex} from '../../navigation/navigationUtils';
import "../../card/card.scss";
import Text from '../../components/Text';

// import { useAppSelector, useAppDispatch } from "../../app/hooks";
// import {addSlideOkToProceed, selectSlidesOkToProceed} from '../../features/counter/counterSlice';

interface ICardCollection {
  id: string;
  setSectionOkToProceed: any;
  setSlideOkToProceed: any;
}

const cards = [
  {
    textFront: "I have a family history of diabetes",
    textBack: "Having a mother, father, sister, or brother with diabetes increases your risk for type 2 diabetes." ,
    postResponse: "TailoredRisk_FamilyHistory"
  },
  {
    textFront: "I have had high blood pressure",
    textBack: "If you have ever been diagnosed with high blood pressure, this contributes to your risk for type 2 diabetes."  ,
    postResponse: "TailoredRisk_HighBloodPressure"
  },
  {
    textFront: "I'm 40 years of age or older",
    textBack: "You are at a higher risk for type 2 diabetes the older you are.",
    postResponse: "TailoredRisk_OlderThan40"
  },
  {
    textFront: "I'm African American, Hispanic, American Indian/Alaska Native, Asian American, or Pacific Islander",
    textBack: "People from certain racial and ethnic backgrounds are more likely to develop type 2 diabetes than others.",
    postResponse: "TailoredRisk_EthnicGroup"
  },
  {
    textFront: "I'm not physically active",
    textBack: "Being inactive can increase your risk for type 2 diabetes.",
    postResponse: "TailoredRisk_NotPhysicallyActive"
  }
];

function CardYesNoArr({id, setSectionOkToProceed, setSlideOkToProceed}: ICardCollection) {
  //const dispatch = useAppDispatch();

  const clickNextSlide = (i:number, arr:any) => {
    if (i == arr.length - 2) {
      setSectionOkToProceed(id, true);
    }
    //dispatch(addSlideOkToProceed({sectionIndex: id, slideId: i}));
    setSlideOkToProceed(id, i, true);
    gotoNextSlide();
  }

  return (
    <div key={'PersonalRiskFactors'} className={'section'} id={id}>
      <div className="section-inner" style={{height: "100%"}}>
        <div style={{height: "100%"}}>
          <div className="vertical-distribute">
            <Text gutterBottom variant="h4" style={{paddingBottom: '60px'}}>Gestational diabetes increases the risk for developing type 2 diabetes after delivery. There are other personal risk factors, too.</Text>
            <Text variant="pageTitle">Is this true for you?</Text>
              {cards.map(({textFront, textBack, postResponse}, i, arr) => (
                <div className="slide" key={`TailoredRiskSlide${i}`}>
                  <Card 
                    textFront={textFront}
                    textBack={textBack}
                    gotoPrevSlide={gotoPrevSlide}
                    gotoNextSlide={() => { clickNextSlide(i, arr) }}
                    isFirstCard={i == 0}
                    isLastCard={i == cards.length - 1}
                    gotoNextSection={gotoNextSection}
                    postResponse = { postResponse }
                  />
              </div>
              ) )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default CardYesNoArr;
