import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ISlide from "../ISlide";
import Text from '../../components/Text';
import {postUpdate} from '../../data';
import PersonalValues from "./PersonalValues";
import {
  selectPersonalValues,
} from "../../features/counter/counterSlice";
import { alpha, styled } from '@mui/material/styles';

const options = [
  'Not at all',
  'A little bit',
  'Moderately',
  'Quite a bit',
  'Extremely'
]

const postResponse = (question:string, response: string) => {
  console.log('myresponse');
  const questionNumber = parseInt(question.substring(0,1));
  const responseInt = parseInt(response);
  if (questionNumber == 1) {
    //postUpdate('ValuesAffirmation_Statement1', options[responseInt]);
    postUpdate('ValuesAffirmation_Statement1', (responseInt + 1).toString() );
  }
  if (questionNumber == 2) {
    //postUpdate('ValuesAffirmation_Statement2', options[responseInt]);
    postUpdate('ValuesAffirmation_Statement2', (responseInt + 1).toString() );
  }
  if (questionNumber == 3) {
    //postUpdate('ValuesAffirmation_Statement3', options[responseInt]);
    postUpdate('ValuesAffirmation_Statement3', (responseInt + 1).toString() );
  }
}

function ThinkWhy({setSlideOkToProceed, setSlideNotOkToProceed}: ISlide) {

  const personalValues = useAppSelector(selectPersonalValues);
  const [formValues, setFormValues] = useState({} as any);

  const QuestionControl = ({question, id}: {question: string, id: number}) => {
    return (<FormControl style={{alignItems: 'baseline', paddingTop: '30px'}}>
      <Text style={{fontWeight: 600}}>{question}</Text>
      <RadioGroup
        name="enhanced-health-radio-buttons-group"
        row
        onChange={(event, val) => {
          console.log(event);
          console.log(val);
          const newFormVals = {...formValues, ...{[id]: val} };
          setFormValues(newFormVals);
          console.log(newFormVals);
          if (newFormVals[0] && newFormVals[1] && newFormVals[2]) {
            setSlideOkToProceed();
          }
          postResponse(question, val);
        }}
        value={formValues[id]}
      >
        {options.map( (o, i) => <FormControlLabel value={i} control={<Radio />} label={o} />)}
      </RadioGroup>
    </FormControl>);
  }

  return (
    <div id="HowMuchAgree" className="vertical-distribute">
      <PersonalValues values={personalValues}/>
      
      <Text variant="h4" headline>Please tell us how much you agree with the following statements.</Text>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

        <QuestionControl question="1. In general, I try to live up to these values." id={0}/>
        <QuestionControl question="2. These values are an important part of who I am." id={1}/>
        <QuestionControl question="3. I care about these values." id={2}/>

    </div>
    </div>
  );
}

export default ThinkWhy;
