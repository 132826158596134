import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import "../../App.css";
import Text from '../../components/Text';
import {
  selectPersonalValues,
} from "../../features/counter/counterSlice";
import PersonalValues from "./PersonalValues";


function ThinkWhy() {

  const personalValues = useAppSelector(selectPersonalValues);

  return (
    <div>
      <PersonalValues values={personalValues}/>
      <div className="spacer"></div>
      <Text variant="h4" headline>For a moment, think about why these values are important to you.</Text>
    </div>
  );
}

export default ThinkWhy;
