export const gotoPrevSlide = () => {
  (window as any).fullpage_api.moveSlideLeft();
};

export const gotoNextSlide = () => {
  (window as any).fullpage_api.moveSlideRight();
};

export const gotoPrevSection = () => {
  (window as any).fullpage_api.moveSectionUp();
};

export const gotoNextSection = () => {
  (window as any).fullpage_api.moveSectionDown();
};

export const getCurrentSlideIndex = () => {
  return (window as any).fullpage_api?.getActiveSlide()?.index;
};

export const getCurrentSectionIndex = () => {
  return (window as any).fullpage_api?.getActiveSection()?.index();
};
