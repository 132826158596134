import "./App.css";
import PersonalRiskFactors from "./sections/tailored-risk/PersonalRiskFactors";
import HeightBMI from './sections/tailored-risk/HeightBMI';
import FirstYearQuestion from './sections/tailored-risk/FirstYearQuestion';
import ValuesAffirmationSlides from './sections/values-affirmation/ValuesAffirmationSlidesArr';
import WhatReasons from './sections/motivational-interview/WhatReasons';
import KnowledgeIsPower from './sections/motivational-interview/KnowledgeIsPower';
import EnhancedHealthSlides from './sections/enhanced-health/EnhancedHealthSlides';
import MakeAPlan from './sections/action-planning/MakeAPlan';
import YourTurn from './sections/action-planning/YourTurn';
import MISummary from './sections/motivational-interview/MISummary';
import NavigationButtons from './navigation/NavigationButtons'
import Welcome from './sections/welcome/Welcome';
import Closing from './sections/final/Closing';
import Feedback from './sections/final/Feedback';
import ThankYou from './sections/final/ThankYou';
import {gotoNextSection, gotoNextSlide, gotoPrevSection, gotoPrevSlide, getCurrentSlideIndex} from './navigation/navigationUtils';
import { useAppSelector, useAppDispatch } from "./app/hooks";
import Grid from "@mui/material/Grid";
import Text from './components/Text';
import handsHeart from "./images/hands_heart.svg";
import feetDirections from "./images/feet_directions.svg";
import {getUrlParam} from './utils';
import award from "./images/award.svg";

import {
  addSectionsViewable,
  removeSlideOkToProceed,
  addSlideOkToProceed
} from "./features/counter/counterSlice";
import Quotes from './sections/motivational-interview/Quotes';

const modules = {
  valuesAffirmation: {
    cohorts: [0, 1, 16, 8, 7, 2, 13, 9, 3]
  },
  personalizedRisk: {
    cohorts: [0, 14, 4, 11, 15, 2, 13, 9, 3]
  },
  motivationalInterview: {
    cohorts: [0, 5, 6, 11, 15, 8, 7, 9, 3]
  },
  actionPlanning: {
    cohorts: [0, 10, 6, 4, 15, 16, 7, 13, 3]
  }
}

const sections = (setSectionOkToProceed?: any, setSlideOkToProceed?: any, isSession2 = true ) => {
  const sections = [
    {
      content: <Welcome />,
      okToProceed: true,
      backgroundColor: "#FFF",
      id: 'Welcome'
    },
    {
      slides: <ValuesAffirmationSlides
        setSectionOkToProceed={() => setSectionOkToProceed('ValuesAffirmation')}
        setSlideOkToProceed={setSlideOkToProceed}
        id='ValuesAffirmation'
      />,
      slidesConfig: {
        0: {
          okToProceed: true
        },
        1: {
          okToProceed: false,
          warningMessage: 'Please select two or three values that are most important to you.',
          validationWarnOnly: false
        },
        2: {
          okToProceed: true
        },
        3: {
          okToProceed: false,
          warningMessage: 'You haven\'t entered the requested information. Please click Next to continue anyway.',
          validationWarnOnly: true
        },
        4: {
          okToProceed: false,
          warningMessage: 'You haven\'t submitted a response to each of the questions. Please click Next to continue anyway.',
          validationWarnOnly: true
        }
      },
      okToProceed: false,
      backgroundColor: "#6262c0",
      id: 'ValuesAffirmation',
      darkText: true,
      module: modules.valuesAffirmation
    },
    {
      content: (
        // <ImgLayout
        //   img={image1}
        //   isImgOnLeft
        // >
        <div>
          <div className="vertical-distribute">
            {/* <Text variant="pageTitle">
              At the Regional Perinatal Service Center, we value the health of patients like you who have had gestational diabetes
            </Text> */}
            <Text variant="pageTitle">
              At the Regional Perinatal Service Center, we value the health of patients like you who have had gestational diabetes.
            </Text>
            <img src={handsHeart}/>
          </div>
        </div>
        // </ImgLayout>
        ),
      backgroundColor: "#ff5f45",
      okToProceed: true,
      id:'EnhancedHealthIntro',
      darkText: true
    },
    {
      content: (
        // <ImgLayout
        //   img={image1}
        //   isImgOnLeft
        // >
        <div>
          <div className="vertical-distribute">
            {/* <Text variant="pageTitle">
              At the Regional Perinatal Service Center, we value the health of patients like you who have had gestational diabetes
            </Text> */}
            <Text variant="h4">
              As an important part of your healthcare, we encourage you to <b>test for type 2 diabetes around 6 weeks after delivering your baby</b>. Let's move on to see what this test is all about. 
            </Text>
          </div>
        </div>
        // </ImgLayout>
        ),
      backgroundColor: "#ff5f45",
      okToProceed: true,
      id:'EnhancedHealth',
      darkText: true
    },
    {
      id: 'EnhancedHealthSlides',
      // slides: <CardArr 
      slides: <EnhancedHealthSlides id='EnhancedHealthSlides' />,
      backgroundColor: "#0798ec",
      hideStandardNavigation: false,
      slidesConfig: {
        okToProceed: true,
        setSectionOkToProceed: () => setSectionOkToProceed('EnhancedHealthSlides')
      }
    },
    {
      content: <FirstYearQuestion id='FirstYear' setSectionOkToProceed={() => setSectionOkToProceed('FirstYear')}/>,
      backgroundColor: "#ff5f45",
      id:'FirstYear',
      okToProceed: false,
      warningMessage: 'Looks like you haven\'t answered the question correctly yet. Please click Next to continue anyway.',
      validationWarnOnly: true,
      module: modules.personalizedRisk
    },
    {
      id: 'PersonalRiskFactors',
      slides: <PersonalRiskFactors 
        id = 'PersonalRiskFactors'
        setSectionOkToProceed={() => setSectionOkToProceed('PersonalRiskFactors')}
        setSlideOkToProceed={setSlideOkToProceed}
      />,
      backgroundColor: "#0798ec",
      module: modules.personalizedRisk
    },
    {
      slides: <HeightBMI id='BMI' setSectionOkToProceed={() => setSectionOkToProceed('BMI')} setSlideOkToProceed={setSlideOkToProceed}/>,
      backgroundColor: "#ff5f45",
      id:'BMI',
      okToProceed: false,
      warningMessage: 'Looks like you haven\'t answered these questions yet. Please click Next to continue anyway.',
      validationWarnOnly: true,
      slidesConfig: {
        0: {
          okToProceed: true
        },
        1: {
          okToProceed: false,
          warningMessage: 'Looks like you haven\'t answered these questions yet. Please click Next to continue anyway.',
          validationWarnOnly: true
        },
        2: {
          okToProceed: true
        }
      },
      module: modules.personalizedRisk
    },
    {
      content: (
        <div style={{height: "100%"}}>
          <div className="vertical-distribute">
            <Text variant="pageTitle">
              It's your choice
            </Text>
            <img src={feetDirections}/>
          </div>
        </div>
        ),
      backgroundColor: "#ff5f45",
      okToProceed: true,
      id:'WhatReasonsIntro',
      darkText: false,
      module: modules.motivationalInterview
    },

    {
      slides: <WhatReasons 
        id = 'WhatReasons'
        setSectionOkToProceed={() => setSectionOkToProceed('WhatReasons')}
        setSlideOkToProceed={setSlideOkToProceed}
      />,
      backgroundColor: "#ff5f45",
      okToProceed: false,
      slidesConfig: {
        0: {
          okToProceed: false,
          warningMessage: 'Please select a reason.',
          validationWarnOnly: false
        },
        1: {
          okToProceed: false,
          warningMessage: 'Please select a reason.',
          validationWarnOnly: false
        }
      },
      id:'WhatReasons',
      module: modules.motivationalInterview
    },
    {
      slides: (
        <Quotes id='Quotes'
        setSectionOkToProceed={() => setSectionOkToProceed('Quotes')}
        />
      ),
      okToProceed: true,
      id:'Quotes',
      slidesConfig: {
        0: {
          okToProceed: true
        },
        1: {
          okToProceed: true
        }
      },
      module: modules.motivationalInterview
    },
    {
      slides: <KnowledgeIsPower
        id={'KnowledgeIsPower'}
        setSectionOkToProceed={() => setSectionOkToProceed('KnowledgeIsPower')}
        />,
      backgroundColor: "#ff5f45",
      okToProceed: true,
      slidesConfig: {
        0: {
          okToProceed: true
        },
        1: {
          okToProceed: true
        },
        2: {
          okToProceed: true
        },
        3: {
          okToProceed: true
        }
      },
      id:'KnowledgeIsPower',
      module: modules.motivationalInterview
    },
    {
      slides: <MISummary 
        id = 'MISummary'
        setSectionOkToProceed={() => setSectionOkToProceed('MISummary')}
        setSlideOkToProceed={setSlideOkToProceed}
      />,
      backgroundColor: "#ff5f45",
      okToProceed: false,
      slidesConfig: {
        0: {
          okToProceed: true
        },
        1: {
          okToProceed: false,
          warningMessage: 'Looks like you haven\'t answered the question yet. Please click Next to continue anyway.',
          validationWarnOnly: true
        }
      },
      id:'MISummary',
      module: modules.motivationalInterview
    },
    {
      slides: <MakeAPlan
        id = 'MakeAPlan'
        setSectionOkToProceed={() => setSectionOkToProceed('MakeAPlan')}
      />,
      backgroundColor: "#ff5f45",
      okToProceed: true,
      slidesConfig: {
        0: {
          okToProceed: true,
        },
        1: {
          okToProceed: true
        },
        2: {
          okToProceed: true
        },
        3: {
          okToProceed: true
        },
        4: {
          okToProceed: true
        }
      },
      id:'MakeAPlan',
      module: modules.actionPlanning
    },
    {
      slides: <YourTurn
        id = 'YourTurn'
        setSectionOkToProceed={() => setSectionOkToProceed('YourTurn')}
        setSlideOkToProceed={setSlideOkToProceed}
      />,
      backgroundColor: "#ff5f45",
      okToProceed: false,
      slidesConfig: {
        0: {
          okToProceed: false,
          warningMessage: 'You haven\'t entered the requested information. Please click Next to continue anyway.',
          validationWarnOnly: true
        },
        1: {
          okToProceed: false,
          warningMessage: 'You haven\'t entered the requested information. Please click Next to continue anyway.',
          validationWarnOnly: true
        },
        2: {
          okToProceed: false,
          warningMessage: 'You haven\'t entered the requested information. Please click Next to continue anyway.',
          validationWarnOnly: true
        },
        3: {
          okToProceed: false,
          warningMessage: 'You haven\'t entered the requested information. Please click Next to continue anyway.',
          validationWarnOnly: true
        },
        4: {
          okToProceed: true
        }
      },
      id:'YourTurn',
      module: modules.actionPlanning
    },
    {
      content: 
        <div>
          <div className="vertical-distribute">
            <div className='spacer'/>
            <Text variant="h1">Nice work!</Text>
            <img src={award} style={{height: '70%'}}/>
          </div>
        </div>,
      backgroundColor: "#ff5f45",
      okToProceed: true,
      id:'NiceWork',
      module: modules.actionPlanning
    },
    {
      content: 
        <div>
          <div className="vertical-distribute">
            <Closing/>
          </div>
        </div>,
      backgroundColor: "#ff5f45",
      okToProceed: true,
      slidesConfig: {
        0: {
          okToProceed: true,
        },
        1: {
          okToProceed: true
        }
      },
      id: 'Closing'
    },
    {
      content: 
        <div>
          <div className="vertical-distribute">
            <ThankYou
              isLastSlide = {!(!!isSession2)}
            />
          </div>
        </div>,
      backgroundColor: "#ff5f45",
      okToProceed: true,
      slidesConfig: {
        0: {
          okToProceed: true,
        },
        1: {
          okToProceed: true
        }
      },
      id: 'ThankYou'
    },    

  ] as any[];

  const feedback = {
    content: (
      <Feedback/>
    ),
    backgroundColor: "#ff5f45",
    okToProceed: true,
    id:'Feedback'
  };

  return isSession2 ? [...sections, feedback] : sections;
};



function SectionsContent():any {
  //const [sectionsOkToProceed, setSectionsOkToProceed] = useState([] as any[]);

  const dispatch = useAppDispatch();

  const getSectionIndex = (sectionId: string) => getSectionsFilteredByCohort(sections())?.findIndex((o:any) => o.id == sectionId);

  const setSectionOkToProceed = (sectionId:string, okToProceed:boolean = true) => {
    const sectionIndex = getSectionIndex(sectionId);
    if (okToProceed) {
      dispatch(addSectionsViewable(sectionIndex + 1));
    }
  }

  //const setSlideOkToProceed = (sectionId: number, slideId: number, okToProceed:boolean = true) => {
  const setSlideOkToProceed = (sectionId: string, slideId: number, okToProceed:boolean = true) => {
    const sectionIndex = getSectionIndex(sectionId);
    if (okToProceed) {
      dispatch(addSlideOkToProceed({sectionIndex: sectionIndex, slideId: slideId}));
    } else {
      dispatch(removeSlideOkToProceed({sectionIndex: sectionIndex, slideId: slideId}));
    }
  }

  const session = getUrlParam('m');

  return (    
    getSectionsFilteredByCohort(sections(setSectionOkToProceed, setSlideOkToProceed, !session || session == '2')).map( ({content, slides, darkText, id}:any, i: Number, arr: any[]) => (
      
        slides && (slides) || (

      <div key={`section${i}`} className={darkText ? 'section dark-text' : 'section'} id={id}>

         {content && (<div className='section-inner height-remaining-nav'>
            {content}
           </div>
         )}

          <NavigationButtons
            goForward={gotoNextSection}
            goBack={gotoPrevSection}
            hideForward = {i == arr.length - 1}
            hideBack = {i == 0}
            absolutePosition
          />

      </div>
    )

    ))


  )
}

function getSectionsFilteredByCohort (sections: any) {
  const cc = getUrlParam('cc');
  const cohort = parseInt(!cc || isNaN(cc) ? 0 : cc);
  return sections?.filter((o: any) => !o.module || o.module?.cohorts?.includes(cohort));
}

export const getSectionsFilteredByOkToProceed = (sectionsOkToProceed: any[], setSectionOkToProceed?: any) => {
  let results: any[] = [];
  const sectionsLoaded = getSectionsFilteredByCohort(sections(setSectionOkToProceed));
  //const sectionsLoaded = sections(setSectionOkToProceed);
  if (sectionsLoaded.length) {
    results[0] = sectionsLoaded[0];
    for (let i: number = 1; i < sectionsLoaded.length; i++) {
        if (sectionsLoaded[i-1].okToProceed || sectionsOkToProceed.includes(sectionsLoaded.indexOf(sectionsLoaded[i]))) {
          results[i] = sectionsLoaded[i];
      } else {
        break;
      }
    }
  }
  console.log('results');
  console.log(results);
  return results;
}

export const getSectionById = (id: number) => getSectionsFilteredByCohort(sections())[id];
export default SectionsContent;