import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SlideNavigation from "../../navigation/SlideNavigation";
import "../../App.css";
import Text from '../../components/Text';
import ICardCollection from "../ICardCollection";
import idea from "../../images/idea.svg";
import CardWithTitle from '../../components/CardWithTitle';

const intro = (<div>
  <Text variant="pageTitle">Knowledge is power</Text>
  {/* <Text>If you choose to test for type 2 diabetes after delivery, results will be sent to your kp.org account and your provider will contact you. You'll receive support personalized to your results.</Text> */}
</div>);

const cards = [
  {
    title: "If the test result is normal",
    // backgroundColor: "rgb(120 255 148 / 80%)",
    backgroundColor: "rgb(255 194 69)",
    content: (
      <ul>
        <li>You can <b>lower your risk for type 2 diabetes</b> with Wellness Coaching. A personal coach can help you manage weight, eat well, and be active. It's <b>free</b> for Kaiser members.</li>
        <li>Take a test to check your blood sugar every year.</li>
      </ul>
    )
  },
  {
    title: <Text variant="h3">If the test shows you have <span style={{whiteSpace: 'nowrap'}}>pre-diabetes</span></Text>,
    //backgroundColor: "rgb(255 230 158)",
    backgroundColor: "rgb(255 194 69)",
    content: (
      <ul>
        <li>You can help <b>prevent or delay type 2 diabetes</b> with Wellness Coaching. A personal coach can help you manage weight, eat well, and be active. It's <b>free</b> for Kaiser members.</li>
        <li>Take a test to check your blood sugar every year.</li>
      </ul>
    )
  },
  {
    title: <Text variant="h3">If the test shows you have <span style={{whiteSpace: 'nowrap'}}>type 2 diabetes</span></Text>,
    //backgroundColor: "#93E1D8",
    backgroundColor: "rgb(255 194 69)",
    content: (
      <ul>
        <li>You can <b>live healthy with diabetes</b> by getting treatment early. Your doctor will help you learn ways to manage your blood sugar and prevent complications.</li>
        <li>Wellness Coaching can help, too. A personal coach can help you manage weight, eat well, and be active. It's <b>free</b> for Kaiser members.</li>
      </ul>
    )
  }
]

function EnahncedHealthSlides({id, setSectionOkToProceed}: ICardCollection) {

  return (
    <SlideNavigation
      id={id}
      setSectionOkToProceed={setSectionOkToProceed}
      //headlineContent={intro}
      slidesArr={[
        <div className="vertical-distribute">
          <Text variant="pageTitle">Knowledge is power</Text>
          <Text variant="h4">If you choose to test for type 2 diabetes after delivery, results will be sent to your kp.org account and your provider will contact you. You'll receive support personalized to your results.</Text>
          <img src={idea} />
        </div>
        ,
        ...cards.map(({title, content, backgroundColor}, i, arr) => (
        <div>
          <CardWithTitle
            title={title}
            content={content}
            backgroundColor={backgroundColor}
          />
        </div>
    
      ) )]}
      
    />
  );
}

export default EnahncedHealthSlides;
