import {getUrlParams, getUrlParam} from './utils';

declare global {
    interface Window { EngagementId: any; }
}

window.EngagementId = window.EngagementId || {};

const columns = {
    ValuesAffirmation_Values: 'ValuesAffirmation_Values',
    ValuesAffirmation_Why: 'ValuesAffirmation_Why',
    ValuesAffirmation_Statement1: 'ValuesAffirmation_Statement1',
    ValuesAffirmation_Statement2: 'ValuesAffirmation_Statement2',
    ValuesAffirmation_Statement3: 'ValuesAffirmation_Statement3',
    TailoredRisk_FamilyHistory: 'TailoredRisk_FamilyHistory',
    TailoredRisk_HighBloodPressure: 'TailoredRisk_HighBloodPressure',
    TailoredRisk_OlderThan40: 'TailoredRisk_OlderThan40',
    TailoredRisk_EthnicGroup: 'TailoredRisk_EthnicGroup',
    TailoredRisk_NotPhysicallyActive: 'TailoredRisk_NotPhysicallyActive',
    TailoredRisk_AsianAmerican: 'TailoredRisk_AsianAmerican',
    TailoredRisk_Height: 'TailoredRisk_Height',
    MI_ReasonToTest: 'MI_ReasonToTest',
    MI_ReasonToTest_Other: 'MI_ReasonToTest_Other',
    MI_ReasonToNotTest: 'MI_ReasonToNotTest',
    MI_ReasonToNotTest_Other: 'MI_ReasonToNotTest_Other',
    MI_Response: 'MI_Response',
    ActionPlanning_When: 'ActionPlanning_When',
    ActionPlanning_Who: 'ActionPlanning_Who',
    ActionPlanning_What: 'ActionPlanning_What',
    ActionPlanning_How: 'ActionPlanning_How',
    Completed: 'Completed'
}

export const post = (column: string, value: string, participantId?: string, sessionId?: number) => {
    const params = getUrlParams('engagementId', 'admin', 'debug');
    if (!params.admin && !params.debug) {
        const axios = require('axios').default;
        axios.post('https://sunrisefunctions.azurewebsites.net/api/Update', {
            EngagementId: params.engagementId || window.EngagementId,
            Column: column,
            Value: value
        })
        .then(function (response:any) {
            console.log(response);
        })
        .catch(function (error:any) {
            console.log(error);
        });
    }
};

export const postUpdate = (column: keyof typeof columns, value: string) => {
    post(columns[column], value);
}

export const createItem = () => {
    const params = getUrlParams('u', 'm', 'cc', 'xp', 'admin', 'debug');
    if (!params.admin && !params.debug) {
        const axios = require('axios').default;
        axios.post('https://sunrisefunctions.azurewebsites.net/api/CreateItem', {
            StudyId: params.u || 0,
            SessionId: params.m || 0,
            CC: params.cc || 0,
            ExpirationDate: params.xp || '12/19/2022'
        })
        .then(function (response:any) {
            console.log(response);
            window.EngagementId = response && response.data;
        })
        .catch(function (error:any) {
            console.log(error);
        });
    }
};

export const postCompleted = () => {
    post(columns['Completed'], 'web service will set this value because of the column name');
}

