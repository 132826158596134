import React, { useState } from "react";
import "../App.css";
import Stack from "@mui/material/Stack";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import Fab from "@mui/material/Fab";
import UndoIcon from "@mui/icons-material/Undo";


interface INavigation {
    goBack: any;
    goForward: any;
    hideBack?: boolean;
    hideForward?: boolean;
    showHorizontalBack?: boolean;
    showHorizontalForward?: boolean;
    absolutePosition?: boolean;
}

export default function Navigation({goBack, goForward, hideBack, hideForward, showHorizontalBack, showHorizontalForward, absolutePosition}: INavigation) {
    return (
    <Stack spacing={10} direction="row" className={absolutePosition ? 'navigation-absolute navigation' : 'navigation'}>

    {!hideBack && <Fab
        variant="extended"
        size="medium"
        color="primary"
        onClick={goBack}
        className="card-button-back-front"
        // style={{display: props.isFirstCard ? 'none' : 'inline-flex'}}
      >
        {/* <ArrowBack sx={{ mr: 1 }} /> */}
        {showHorizontalBack && <ArrowBack  sx={{ mr: 1 }} />}
        {!showHorizontalBack && <ArrowUpward  sx={{ mr: 1 }} />}
        Back
      </Fab>}

      {!hideForward && <Fab
        variant="extended"
        size="medium"
        color="primary"
        onClick={goForward}
        className="card-button-back-front"
        // style={{display: props.isFirstCard ? 'none' : 'inline-flex'}}
      >
        {/* <ArrowForward sx={{ mr: 1 }} /> */}
        {showHorizontalForward && <ArrowForward sx={{ mr: 1 }} />}
        {!showHorizontalForward && <ArrowDownward sx={{ mr: 1 }} />}
        
        Next
      </Fab>}

      {/* <Button variant="contained" size="large" onClick={props.gotoNextSection} startIcon={<ArrowUpward />}>Back</Button> */}
      {/* {i != arr.length - 1 && <Button variant="contained" size="large" onClick={props.gotoNextSection} endIcon={<ArrowDownward />}>Next</Button>} */}
      
    </Stack>
    )
}